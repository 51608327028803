/*========== LOGIN ACTIONS ============= */

export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const toggleLogin = () => ({
  type: 'TOGGLE_LOGIN',
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});


/*========== TRAANSACTION ACTIONS ============= */

export const getTransactionData = () => ({
  type: 'GET_TAX_DATA'
});

export const setTaxData = (data) => ({
  type: 'SET_TAX_DATA',
  payload: data
});

export const toggleInfoModal = (data) => ({
  type: 'TOGGLE_INFO_MODAL',
  payload: data
});

/*========== KYC ACTIONS ============= */

export const getKycStatus = (data) => ({
  type: 'GET_KYC_STATUS',
  payload: data
});

export const getXdcPrice = () => ({
  type: 'GET_XDC_PRICE',
});

export const setXdcPrice = (data) => ({
  type: 'SET_XDC_PRICE',
  payload: data
});

export const getAllDonations = () => ({
  type: 'GET_ALL_DONATIONS',
});

export const setAllDonations = (data) => ({
  type: 'SET_ALL_DONATIONS',
  payload: data
});

export const setDonationsNames = (data) => ({
  type: 'SET_DONATIONS_NAMES',
  payload: data
});

export const setKycStatus = (data) => ({
  type: 'SET_KYC_STATUS',
  payload: data
});

export const setKyc = (data) => ({
  type: 'SET_KYC',
  payload: data
});

export const kycResponse = (data) => ({
  type: 'KYC_RESPONSE',
  payload: data
});

// kycSubmission({ payload: data })

export const kycSubmit = (data) => (
  console.log("********** data",data),{
  type: 'KYC_SUBMIT',
  payload: data
});


export const kynSubmit = (data) => ({
  type: 'KYN_SUBMIT',
  payload: data
});

export const kynGET = (data) => ({
  type: 'KYN_GET',
  payload: data
});

export const kynSET = (data) => ({
  type: 'KYN_SET',
  payload: data
});