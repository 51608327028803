import Logo from "../images/cifilogo.png";

import { useState } from "react";
import DonationPopup from "../components/DonationPopup";
import Kyc from "../components/Kyc";
import Kyn from "../components/Kyn";
import Profile from "./Landing/profile";

export default function Header() {
  const [headerBar, showHeaderBar] = useState(false);
  const [donationPopup, showDonationPopup] = useState(false);
  const [profile, showProfile] = useState(false);
  const [kyc, showKyc] = useState(false);
  const [kyn, showKyn] = useState(false);

  return (
    <div
      className="z-[999] fixed top-0 right-0 left-0 bg-white/[0.96]"
    >
      {donationPopup && <DonationPopup showDonationPopup={showDonationPopup} />}
      {kyc && <Kyc showKyc={showKyc} />}
      {kyn && <Kyn showKyn={showKyn} />}

      <nav className="container flex items-center justify-between py-2 md:py-4 h-[70px] md:h-[90px]">
        <div className="">
          <a className="logo-wrap inline-flex items-center text-[#272b42] text-lg font-bold" href="/">
            <img src={Logo} alt="logo" className="mr-[10px]" /> CifiGive
          </a>
        </div>
        <ul
          className={`${headerBar === true
            ? "min-h-[180px] opacity-100  py-6 px-5  z-50"
            : "h-0  md:h-auto overflow-hidden opacity-0 md:opacity-100"
            } md:justify-end items-center transition-[all_600ms] block drop-shadow-lg none md:flex md:p-0 absolute bg-white md:bg-transparent top-[50px] left-0 right-0 md:top-0 md:relative text-black text-lg`}
        >
          <li className="mb-3 md:mr-[10px] md:mb-0">
            <a
              href="/profile"
              className="profile-link bg-gradient text-white rounded-[40px] text-[15px] font-medium "
            >
              Profile
            </a>
          </li>
          <li className="mb-3 md:mr-[10px] md:mb-0">
            <button
              onClick={() => showKyc(true)}
              className="bg-[#18181B] text-white w-[150px] h-14 rounded-[40px] text-[14px] font-medium"
            >
              FUNDRAISERS
            </button>
          </li>

          <li className="mb-3 md:mr-[10px] md:mb-0">
            <button
              onClick={() => showKyn(true)}
              className="bg-[#18181B] text-white w-[150px] h-14 rounded-[40px] text-[14px] font-medium"
            >
              KYN
            </button>
          </li>

          <li>
            <button
              onClick={() => showDonationPopup(true)}
              className="bg-gradient text-white w-[150px] h-14 rounded-[40px] text-[15px] font-medium"
            >
              DONORS
            </button>
          </li>
        </ul>
        {/* <button
          className="inline-block md:hidden"
          onClick={() => showHeaderBar(!headerBar)}
        >
          <img
            src="/images/bars-solid.svg"
            alt="menu-bars"
            className="w-4 h-4"
          />
        </button> */}
      </nav>
    </div>
  );
}
