const { web3 } = require('../web3');
const { env } = require('../config');

const ClientData = require(`./${env}/Client.json`);
const TokenData = require(`./${env}/Token.json`);

// const networks = {
//   0: 'Disconnected',
//   1: 'Mainnet',
//   4: 'Rinkeby',
//   42: 'Kovan',
// }

const ClientABI = ClientData['abi'];

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);


module.exports = {
  Token, TokenABI, TokenAddress,
  ClientABI,
};
