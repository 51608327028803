import AOS from "aos";
import Kyc from "../Kyc";
import "aos/dist/aos.css";
import Header from "../Header";
import Footer from "../Footer";
import Profile from "./profile";
import EventBus from "eventing-bus";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import DonationPopup from "../DonationPopup";
import Submit from "../../images/submit.png";
import Sec4A from "../../images/Sec-4(1).png";
import Sec4B from "../../images/Sec-4(2).png";
import Sec4C from "../../images/Sec-4(3).png";
import Secure from "../../images/cifilogo.png";
import Donors from "../../images/donors.png";
import Support from "../../images/support.png";
import Banner from "../../images/Banner-top.png";
import together from "../../images/together.png";
import { HashLink } from 'react-router-hash-link';
import "owl.carousel/dist/assets/owl.carousel.css";
import Fundraisers from "../../images/fundraisers.png";


export default function App() {
  useEffect(() => {
    EventBus.on("info", (e) => toast.info(e));
    EventBus.on("error", (e) => toast.error(e));
    EventBus.on("success", (e) => toast.success(e));
    EventBus.on("tokenExpired", () => this.props.logout());
    AOS.init();
    AOS.init({
      // Global settings:
      disable: "phone", // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    });
  }, []);


  const [donationPopup, showDonationPopup] = useState(false);
  const [kyc, showKyc] = useState(false);
  const [profile, showProfile] = useState(false);
  const [submitKyc] = useState([
    "ID Card Verification",
    "Registration Number",
    "Face Verification",
    "Biometric Verification",
    "Utility Bills as Proof of Address",
  ]);
  const [fundRaising] = useState([
    {
      img: Sec4A,
      head: "Take an initiative",
      detail: "Enter your name and location",
    },
    {
      img: Sec4B,
      head: "Speak up your mind",
      detail: "We are here to listen and guide you",
    },
    {
      img: Sec4C,
      head: "Share with Friends & Family",
      detail: "Spread the word about your cause to everybody",
    },
  ]);
  const [donations] = useState([
    { img: "/images/foster.jpg", name: "Foster Kids" },
    { img: "/images/veterans.jpg", name: "Veterans" },
    { img: "/images/birthing.jpg", name: "Natural Birthing Centers" },
    { img: "/images/communities.jpg", name: "Communities in Need" },
    {
      img: "/images/callisthenics.jpg",
      name: "Creation of Callisthenics Parks",
    },
    { img: "/images/responders.jpg", name: "Emergency Responders" },
  ]);

  return (
    <div className="overflow-hidden">
      <Header />
      {donationPopup && <DonationPopup showDonationPopup={showDonationPopup} />}
      {kyc && <Kyc showKyc={showKyc} />}
      {profile && <Profile showProfile={showProfile} />}
      <div className="relative sec-1 ">
        <img
          src={Banner}
          className="desk-img md:absolute hidden md:inline-block right-0 top-0 lg:top-[50%] ml-auto bottom-0 max-h-screen ty-50 w-full md:w-auto object-cover fil-gr -z-50 mt-[70px] md:mt-[140px] lg:mt-[50px] max-w-[650px]"
          alt="banner"
        />
        <img src={Banner} alt="banner" className="block md:hidden pt-[70px] fil-gr w-full object-cover" />
        <div className="container py-10 md:py-0 md:h-screen">
          <div
            className="md:max-w-[580px] h-full flex flex-col justify-center text-center md:text-left"
          >
            <h4 className="text-[#18181B] font-normal text-[36px] md:text-[48px] lg:text-[60px] leading-[1.3em] -mb-3">
              Looking for a way to make a difference in the world?
            </h4>
            <p className=" lg:text-[16px] leading-[1.5em] text-[#18181B] font-normal mt-6 mb-8 md:max-w-[385px] lg:max-w-full ">
              As a non-profit initiative, we at CiFi Give believe in the power
              of direct donations to make the world a better place for everyone.
            </p>
            <div className="flex flex-wrap items-center gap-[10px] justify-center md:justify-start">
              <button
                onClick={() => showKyc(true)}
                className="bg-[#18181B] text-white w-[47%] md:w-[180px] h-[62px] rounded-[40px] text-[13px] md:text-[15px] font-medium"
              >
                FUNDRAISERS
                <span className="block text-[#3be3cc] text-[10px] md:text-xs font-medium">
                  SUBMIT KYC
                </span>
              </button>
              <button
                onClick={() => showDonationPopup(true)}
                className="bg-gradient text-white w-[47%] md:w-[180px] h-[62px] rounded-[40px] text-[13px] md:text-[15px] font-medium"
              >
                DONATE TODAY
                <span className="block text-[#18181B] text-[10px] md:text-xs font-medium">
                  START DONATION
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="relative sec-2 sec">
        <div className="container pt-4 md:pt-16">
          <div className="inner">
            <div className="img-wrap">
                <img
                src={together}
                className="fil-gr rounded-[10px]"
                alt="banner"
              />
            </div>
            <div
              className="content max-w-[576px] h-full flex flex-col items-center md:items-start ml-auto md:pt-10 text-center md:text-left justify-center"
            >
              <h4 className="text-[#18181B] text-[36px] md:text-[40px] font-normal leading-[1.3em] -mb-3">
                Together, we can make a  positive impact
              </h4>
              <p className="text-[14px] leading-[1.5em] text-[#18181B] font-normal mt-6 mb-8 md:max-w-[576px] lg:max-w-full">
                That's why we're facilitating donors to give to the causes they
                care about most. <br />
                <br /> Currently, we're directing donations to support foster
                kids, veterans, the creation of calisthenics parks, global
                emergency services, and natural birthing centers
              </p>
              <HashLink
                className=""
                smooth to='/#causes'
              >
                <button
                  className="bg-[#18181B] text-white w-[202px] h-[49px] rounded-[40px] text-lg font-medium mt-6 md:mt-0"
                >
                  Discover Causes
                </button>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-14 md:mt-[100px] sec-3 sec">
        <div className="container mt-14 md:mt-0 md:py-[100px] lg:py-[154px]">
          <div className="inner">
            <div
              className="content md:max-w-[576px] text-center md:text-left h-full flex flex-col justify-center"
            >
              <h4 className="text-[#18181B] font-normal text-[36px] md:text-[40px] leading-[1.3em] -mb-3">
                We can create a brighter tomorrow
              </h4>
              <p className="text-[14px] leading-[1.5em] text-[#18181B] font-normal mt-6 mb-8 md:max-w-[576px] lg:max-w-full">
                We've carefully set limits for each of these causes based on their
                urgency and need. <br />
                <br /> By donating through us, you can feel confident that your
                funds are going directly to the causes that matter most to you.
              </p>
            </div>

              <div className="img-wrap">
                <img
                src={together}
                className="fil-gr rounded-[10px]"
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>

      <div  className="overflow-hidden sec-4">
        <div className="container">
          <div className="pt-[106px] md:pt-[55px] pb-[60px] md:pb-[100px]">
            <div className="max-w-[650px] mx-auto pattern">
              <h4 data-aos="fade" data-aos-duration="1500" className="text-center text-[#18181B] font-normal text-[36px] md:text-[40px] lg:text-[56px] leading-[1.3em] mb-5">
               Raising funds on CiFi Give is quick & easy
              </h4>
              <p className="text-[16px] text-[#18181B] text-center">
                All you need to do is:
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 mt-12 gap-5 md:gap-8  py-[12px]">
              {fundRaising.map((value, index) => (
                <div className={`box`} key={index}>
                  <img src={value.img} alt="first" className="mx-auto" />
                  <h4 className="text-[24px] text-center font-normal text-[#18181B] flex justify-center items-center leading-[1.3em] mt-6 mb-4">
                    {value.head}
                  </h4>
                  <p className="text-[#18181B] text-center text-[16px] font-normal md:leading-[1.5em]">
                    {value.detail}
                  </p>
                </div>
              ))}
            </div>
            <button
              onClick={() => showKyc(true)}
              className="bg-[#18181B] text-white w-[240px] h-[62px] rounded-[40px] text-lg font-medium mx-auto block mt-20"
            >
              Start Raising Funds
              <span className="block text-[#3be3cc] text-xs font-medium mt-1">
                SUBMIT KYC
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="sec-5">
        <div className="py-[60px] md:py-[90px] relative overflow-hidden">
         
          <div id="causes" className="container overflow-hidden">
            <div className="text-center md:text-left">
              <img src={Secure} alt="secure" className="w-[92px] mb-12 mx-auto md:mx-0 " />
              <div className="">
                <h4 className="text-[24px] font-normal text-[#18181B] mb-4">
                  Why Donate to CiFi Give?
                </h4>
                <h4 className="text-[#18181B] font-normal text-[36px] md:text-[40px] lg:text-[56px] leading-[1.3em] mb-4">
                  Transform your crypto Into community empowerment
                </h4>
                <h4 className="text-[16px] font-normal text-[#18181B]">
                  Your every single penny donation can make a big impact on the
                  lives of:
                </h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] mt-20">
                {donations.map((value, index) => (
                  <div
                    className="w-full overflow-hidden relative box"
                    key={index}
                  >
                    <div className="img-wrap">
                      <img
                        src={value.img}
                        alt={value.name}
                        className="h-full w-full object-cover rounded-[8px] mb-6"
                      />
                      </div>
                    
                    <span className="text-[21px] font-medium text-[#18181B] text-center block">
                      {value.name}
                    </span>
                  </div>
                ))}
              </div>
              <p className="text-[16px] font-normal text-[#18181B] mt-20 mb-12">
                These causes are pressing issues that we see every day, and it's
                crucial that we work together to address them. With your
                support, we can make a difference and turn these causes into
                successful stories of social change.
              </p>
              <button
                onClick={() => showDonationPopup(true)}
                className="bg-gradient w-[200px] h-[64px] rounded-[50px] text-[18px] font-medium mx-auto block mt-5 text-white"
              >
                Start DONATION
                <span className="block text-xs font-medium text-[#18181B]">
                  DONATE TODAY
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative md:mt-[100px] sec sec-6">
        <div className="container py-10">
          <div className="inner">
            <div
              className="content md:max-w-[480px] lg:max-w-[580px] h-full flex flex-col justify-center text-center md:text-left"
            >
              <h4 className="text-[24px] font-normal text-[#18181B] mb-4">
                Be a source of change
              </h4>
              <h4 className="text-[#18181B] font-normal text-[36px] md:text-[40px] lg:text-[56px] leading-[1.3em] mb-4">
                Support the cause that matter most to you
              </h4>
              <p className="text-[16px] leading-[1.5em] text-[#18181B] font-normal">
                With <span className="text-[#3be3cc]">CiFi Give</span>, you can
                easily create a fundraising campaign for{" "}
                <span className="text-[#3be3cc]">
                  yourself, your friends and family, or a charitable organization.{" "}
                </span>
                <br />
                <br />
                Every donation, no matter how big or small, helps bring about
                change and makes a positive impact. Be a source of change and
                start your fundraising journey with us today.
              </p>
            </div>

            <div className="img-wrap">
              <img
                src={Support}
                className="fil-gr rounded-[10px]"
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative py-8 md:py-[120px] sec-7">
        <div className="container">
          <div className="max-w-[600px] mx-auto  text-center ">
            <h4 className="text-[36px] md:text-[48px] lg:text-[60px] font-normal text-[#18181B] mb-4">
              How does it work?
            </h4>
            <p className="text-[#18181B] text-[16px] font-normal leading-[1.5em] md:mt-3 max-w-[320px] mx-auto">
              CiFi Give utilizes the
              integration of crypto wallets for both donors and fundraisers.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-10 md:mt-20">
            <div className="text-center box">
              <img src={Donors} alt="donors" className="mx-auto max-h-[260px] md:max-h-full" />
              <h4 className="text-[#18181B] text-[24px] font-normal leading-[1.3em] mt-8 mb-4">
                Donors
              </h4>
              <p className="text-[#18181B] text-[16px] font-normal leading-[1.8em] md:max-w-[504px] mx-auto">
                Donors once connecting their wallet , they can easily donate by
                clicking on the "Start Donation" button, then select their
                cause, follow the process and then make their donation.
              </p>
              <button
                onClick={() => showDonationPopup(true)}
                className="mt-10 w-[150px] text-[16px] text-white font-medium bg-gradient h-[50px] rounded-[40px]"
              >
                Start Donation
              </button>
            </div>

            <div className="text-center box">
              <img src={Fundraisers} alt="donors" className="mx-auto max-h-[260px] md:max-h-full" />
              <h4 className="text-[#18181B] text-[24px] font-normal leading-[1.3em] mt-8 mb-4">
                Fundraisers
              </h4>
              <p className="text-[#18181B] text-[16px] font-normal leading-[1.8em] md:max-w-[504px] mx-auto">
                Must submit KYC to
                integrate crypto wallets and gain access to CiFi Give's features such as adding/updating donation details and viewing
                transactions.
              </p>
              <button
                onClick={() => showKyc(true)}
                className="mt-10 w-[150px] text-[16px] text-white font-medium bg-[#18181B] h-[50px] rounded-[40px]"
              >
                Submit KYC
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-[60px] md:pb-[90px]">
        <div className="p-5 md:p-[60px] bg-[#1a1c29] rounded-[23px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[60px]">
            <div className="flex justify-center flex-col items-center md:items-start md:justify-start text-center md:text-left mb-8 md:mb-0">
              <img src={Submit} alt="kycSubmit" />
              <h4 className="text-[32px] md:text-[39px] lg:text-[48px] font-normal text-white -tracking-[1.5px] leading-[1.2em] mt-7 mb-3">
                How to submit KYC/KYN?
              </h4>
              <p className="text-[16px] leading-[1.8em] font-normal text-white">
                KYC/KYN (Know Your Customer/Non-Profit) is a process required for non-profit
                organizations to verify their identity and become eligible to
                receive funds for their social cause. To submit KYC/KYN, customer/non-profit
                organizations must provide the necessary information to a super
                admin for verification.
              </p>
            </div>
            <div>
              <ul className="flex flex-col gap-[24px]">
                {submitKyc.map((value, index) => (
                  <li className="h-[50px] rounded-[40px] bg-gradient flex items-center px-3 md:px-5" key={index}>
                    <span className="h-[30px] mr-2 md:mr-[10px] rounded-full bg-[#18181B] min-w-[30px] inline-flex items-center justify-center text-[15px] md:text-[17px] font-semibold text-white">
                      {index + 1}
                    </span>
                    <p className="text-[16px] font-medium text-white grow text-center">
                      {value}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
